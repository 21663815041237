import React from "react";
import "./ExperienceLetter.css";
import mobile from "../Assets/mobile.png"
import email from "../Assets/email.png"
import globe from "../Assets/globe.png"

const ConfirmationLetter = (props) => {

  return (
    <div className="outer-container">
      <div id="confirm-letter-container" className="exp-letter-container">
        <div className="top-container">
        <div className="header-container">
            <div className="logo-img">
              <img src={"./images/logo.png"} width={"100%"} alt="Company Logo" />
            </div>
            <div className="headers">
              <p><span className="header-icons"><img src={mobile} alt="mobile" /></span>&nbsp; &nbsp;080-26729977, 9113033662</p>
              <p><span className="header-icons"><img src={email} alt="email"/></span>&nbsp; &nbsp;services@lucidatechnologies.com</p>
              <a href="http://lucidatechnologies.com"><span className="header-icons"><img src={globe} alt="globe"/></span>&nbsp; &nbsp;http://lucidatechnologies.com</a>
            </div>
          </div>
          <h1 className="main-title confirm-title">
            Contract/Probation to FTE confirmation letter
          </h1>
          <br />
          <br />
        </div>

        <div className="paragraph-container">
          <p className="paragraph-content">
            <strong>Employee Name -</strong> {props.empName} <br />
            <br />
            <strong>Employee Number -</strong> {props.empNum} <br />
            <br />
            <strong>Date of joining -</strong> {props.dateOfJoining} <br />
            <br />
            <strong>Designation -</strong> {props.designation}
          </p>
        </div>

        <br />
        <br />

        <div className="paragraph-container">
          <p className="paragraph-content ">
            Dear {props.empName}, <br />
            <br />
            <strong className="bold-text">LUCIDA TECHNOLOGIES PVT LTD</strong>,
            here by confirms that your contract/probation is completed and you
            will be a <strong className="bold-text">Full Time Employee</strong>
            &nbsp;(FTE) effective from &nbsp;
            <strong className="bold-text">
              {props.dateOfJoining ? props.dateOfJoining : "date of joining"}
            </strong>
            , and please treat this letter as official confirmation. 
            <br />
            <br />
            <br />
            <p className="note">
              <strong className="bold-text">
                <span className="confirm-title">Note</span>:- &nbsp; &nbsp;
                &nbsp; (Your designation/role/salary remains the same as
                before).
              </strong>
            </p>
            <br />
            <br />
          </p>
        </div>
        <br />

        <div className="declaration-text">
          <p className="paragraph-text">
            General Manager, <br />
            <br />
            Lucida Technologies, Bengaluru. <br />
            <br />
            Date:
          </p>
          <p className="paragraph-text">
            Employee Name: <br />
            <br />
            Signature:
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationLetter;
