import React, { useEffect, useState , useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getEmployeesList } from "../controllers/controller";
import useStore from "../Store/store";
import "../Components/FormInput/SearchFormData.css"

const InputForm = () => {
  const inputRef = useRef()
  const addData = useStore((state) => state.addData);
  const navigate = useNavigate();
  const [employeesList, setEmployeesList] = useState(null);
  const [email, setEmail] = useState("");
  const [filteredEmail, setFilteredEmail] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isFound, setIsFound] = useState(true);
  const [filteredList, setFilteredList] = useState({});



  const fetchEmployeesListHandler = async () => {
    const resp = await getEmployeesList();
    setEmployeesList(resp);
  };

  useEffect(() => {
    if (employeesList === null) {
      fetchEmployeesListHandler();
    }
  }, [employeesList]);

  const searchHandler = () => {
    // e.preventDefault()

    if (email === "") {
      setIsFound(false);
    } else {
      addData({
        name: filteredList.first_name,
        empid: filteredList.employee_id,
        empnumber: filteredList.id,
        location: "",
        designation: filteredList.designation,
        dateofjoining: filteredList.joining_date,
        dateofrelieving: "",
        authsignature: "",
      });
      setEmail("");
      navigate("/experience-letter");
    }
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const clearHandler = (e) => {
  setEmail("")
  setIsFiltered(false)
   
  }

  return (
    <div className="search-div pt-2">
      <div className="p-1 bg-light rounded rounded-pill shadow-sm">
        <div className="input-group">
          <form>
            <input
              type="search"
              name="filteredEmail"
              placeholder="Enter your official mail address"
              value={email}
              onChange={onChangeHandler}
            />
          </form>
          {/* <input
            type="search"
            placeholder="Enter your Official mail-address?"
            aria-describedby="button-addon1"
            className="form-control border-0 bg-light"
            value={filteredEmail}
            onChange={onChangeHandler}
          /> */}
          <div className="input-group-append">
            <button
              type="submit"
              onClick={clearHandler}
            >Clear</button>
            <button
              id="button-addon1"
              type="submit"
              className="btn btn-link text-primary"
              onClick={searchHandler}
            >
              <FaSearch className="search-icon" />
            </button>
          </div>
        </div>
      </div>

      {!isFiltered &&
        employeesList
          ?.filter((data) => {
            if (
              email !== "" &&
              data.official_email.toLowerCase().includes(email.toLowerCase())
            ) {
              return data;
            }
          })
          .map((data, index) => (
            <div key={index}>
              <p
                onClick={() => {
                  setEmail(data.official_email);
                  setIsFiltered(true);
                  setFilteredList(data)
                }}
              >
                {data.official_email}
              </p>
            </div>
          ))}

      {!isFound && <p>User Not Found</p>}
    </div>
  );
};

export default InputForm;
