import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import "./FormInputStyles.css";

const FormInput = (props) => {
  return (
    <div className={"forminput-container"}>
      <label className="label">{props.label}</label>
      <div className="input-div-container">
        {props.type === "file" ?
          <div className="file-input-style">
            <div className="file-label-div">
              <label className="input-label" htmlFor="file-input">Choose file</label>
              <label className="filename">{props.fileName ? props.fileName : "No file chosen"}</label>
            </div>
            <input
              className="hide-input"
              id="file-input"
              type="file"
              onChange={props.onChange}
              value={props.value}
              placeholder={props.placeholder}
              required={props.required}
              accept={props.accept}
            />
            {props.showIcon &&
              <button className="clear-button">
              <MdDeleteOutline color="#212529" onClick={props.onClearClick} />
            </button>
            }
          </div>
          :
          <input
            className="input"
            type={props.type ? props.type : "text"}
            onChange={props.onChange}
            value={props.value}
            placeholder={props.placeholder}
            required={props.required}
            accept={props.accept}
          />
        }
      </div>
    </div>
  );
};
export default FormInput;
