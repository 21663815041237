let employeeDetails = [
  {
    id: 1,
    label: "Employee Name*",
    placeholder: "Ex: Rahul",
    required: true,
  },
  {
    id: 2,
    label: "Employee ID",
    placeholder: "Ex: a123",
    required: true,
  },

  {
    id: 3,
    label: "Date of Joining*",
    type: "date",
    placeholder: "Choose Date",
    required: true,
  },
  {
    id: 4,
    label: "Date of Relieving*",
    type: "date",
    placeholder: "Choose Date",
    required: true,
  },
  {
    id: 5,
    label: "Designation*",
    placeholder: "Ex: Software Developer",
    required: true,
  },
];

let hrDetails = [
  {
    id: 6,
    label: "Upload Authorized Signature",
    type: "file",
    accept: "image/*",
  },
];

const empTitle = "Employee Details";
const hrTitle = "HR Details";

const experienceSubTitle = "Fill in the details of the employee to generate the experience letter.";
const relievingSubTitle = "Fill in the details of the employee to generate the relieving letter.";
const hrSubTitle = "Please fill the details of HR.";

export {
  employeeDetails,
  hrDetails,
  empTitle,
  hrTitle,
  experienceSubTitle,
  relievingSubTitle,
  hrSubTitle,
};
