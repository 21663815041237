export const FormatDate = (value) => {
  let today = new Date(value);

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = String(today.getFullYear());

  if ((dd && mm && yyyy) === "NaN") {
    return undefined;
  } else {
    return (today = `${dd}-${mm}-${yyyy}`);
  }
};
