import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Components/HomePage/HomePage";
import NavBar from "./Components/NavBar";
import { AppointmentLetterPage } from "./Pages/AppointmentLetterPage";
import { ConfirmationLetterPage } from "./Pages/ConfirmationLetterPage";
import { ExperienceLetterPage } from "./Pages/ExperienceLetterPage/ExperienceLetterPage";
import OfferLetterPage from "./Pages/OfferLetterPage";
import { PreviewPage } from "./Pages/PreviewPage";
import PDF from "./Components/PDF";
import InputForm from "./Components/InputForm";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/experience-letter" element={<ExperienceLetterPage />} />
          <Route path="/offer-letter" element={<OfferLetterPage />} />
          <Route
            path="/appointment-letter"
            element={<AppointmentLetterPage />}
          />
          <Route
            path="/confirmation-letter"
            element={<ConfirmationLetterPage />}
          />
          <Route path="/preview" element={<PreviewPage />} />
          <Route path="/pdf" element={<PDF />} />
          <Route path="/inputForm" element={<InputForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
