import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { MdClose, MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getEmployeesList } from "../../controllers/controller";
import useStore from "../../Store/store";
import "./SearchFormData.css";

const SearchFormData = () => {
  const addData = useStore((state) => state.addData);
  const navigate = useNavigate();
  const [employeesList, setEmployeesList] = useState(null);
  const [email, setEmail] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredEmail, setFilteredEmail] = useState("");
  const [isFound, setIsFound] = useState(true);
  const [filteredList, setFilteredList] = useState({});
  const [isClear, setIsclear] = useState(false);

  const fetchEmployeesListHandler = async () => {
    const resp = await getEmployeesList();
    setEmployeesList(resp);
  };

  useEffect(() => {
    if (employeesList === null) {
      fetchEmployeesListHandler();
    }
  }, [employeesList]);

  const searchHandler = () => {
    setIsFiltered(true);

    if (email === "") {
      setIsFound(false);
      setIsclear(false);
    } else if (email !== filteredEmail) {
      setIsFound(false);
    } else {
      addData({
        name: filteredList.first_name,
        empid: filteredList.employee_id,
        empnumber: filteredList.id,
        location: "",
        designation: filteredList.designation,
        dateofjoining: filteredList.joining_date,
        dateofrelieving: "",
        authsignature: "",
      });
      setEmail("");
      navigate("/experience-letter");
    }
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
    setIsFiltered(false);
    setIsFound(true);
    setIsclear(true);
  };

  const clearHandler = () => {
    setEmail("");
    setIsFiltered(false);
    setIsclear(false);
  };

  return (
    <div className="search-div pt-2">
      <div
        className="col-md-6 col-lg-6 col-11 mx-auto my-auto search-box p-1 bg-light rounded rounded-pill shadow-sm"
        style={{ width: 100 + "%" }}
      >
        <div className="input-group form-container">
          <input
            type="search"
            placeholder="Enter your Official mail-address?"
            aria-describedby="button-addon1"
            className="form-control border-0 bg-light"
            value={email}
            onChange={onChangeHandler}
          />
          {isClear && (
            <button
              id="button-addon1"
              type="submit"
              className="btn btn-link text-primary"
              onClick={clearHandler}
            >
              <MdClose className="clear-icon" />
            </button>
          )}

          <div className="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              className="btn btn-link text-primary"
              onClick={searchHandler}
            >
              <MdArrowForward className="search-icon" />
            </button>
          </div>
        </div>
      </div>

      <div className="result-div">
        {!isFiltered &&
          employeesList
            ?.filter((data) => {
              if (
                email !== "" &&
                data.official_email
                  .toLowerCase()
                  .startsWith(email.toLowerCase())
              ) {
                return data;
              }
            })
            .map((data, index) => (
              <div className="filter-para" key={index}>
                <p
                  onClick={() => {
                    setEmail(data.official_email);
                    setIsFiltered(true);
                    setFilteredList(data);
                    setFilteredEmail(data.official_email);
                  }}
                >
                  {data.official_email}
                </p>
              </div>
            ))}
      </div>

      {!isFound && <p className="result-div" style={{padding :"5px 20px"}}>User Not Found</p>}
    </div>
  );
};

export default SearchFormData;
