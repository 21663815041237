import React from "react";
import offerBanner from "../../Assets/Offer-Letter.svg";
import SearchFormData from "../FormInput/SearchFormData";
import "./HomePage.css";

const HomePage = () => {
  return (
    <>
      <div className="row home-page">
        <div className="col-md-6 first-half">
          <div className="offer-tag">
            <h1 className="offer-title">
              Create Your <br /> <span id="spin"></span> <br /> Letter
            </h1>
            <p className="offer-para">
              {" "}
              Now you can create an offer letter in a matter of clicks.
            </p>
            <SearchFormData />
          </div>
        </div>
        <div className="col-md-6 second-half">
          <img className="offer-img" src={offerBanner} alt="offer-letter" />
        </div>
      </div>
    </>
  );
};

export default HomePage;
