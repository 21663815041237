export let employeeDetails = [
  {
    id: 1,
    label: "Name*",
    type: "text",
    placeholder: "Enter your name",
    required: true,
  },
  {
    id: 2,
    label: "Location*",
    type: "text",
    placeholder: "Enter your location",
    required: true,
  },
  {
    id: 3,
    label: "Designation*",
    type: "text",
    placeholder: "Enter your designation",
    required: true,
  },

  {
    id: 4,
    label: "DOJ*",
    type: "date",
    placeholder: "Enter Special Allowance	",
    required: true,
  },
];

export let hrDetails = [
  {
    id: 5,
    label: "CTC*",
    type: "number",
    placeholder: "Enter CTC",
    required: true,
  },

  {
    id: 6,
    label: "Basic (30% of CTC)	*",
    type: "number",
    placeholder: "Enter Basic CTC",
    required: true,
  },

  {
    id: 7,
    label: "HRA (40% of Basic) *",
    type: "number",
    placeholder: "Enter HRA",
    required: true,
  },

  {
    id: 8,
    label: "Transport Allowance *",
    type: "number",
    placeholder: "Enter Transport Allowance",
    required: true,
  },

  {
    id: 9,
    label: "Telephone Reimbursement	*",
    type: "number",
    placeholder: "Enter Telephone Reimbursement	",
    required: true,
  },

  {
    id: 10,
    label: "Medical Allowance*",
    type: "number",
    placeholder: "Enter Medical Allowance	",
    required: true,
  },

  {
    id: 11,
    label: "Special Allowance	*",
    type: "number",
    placeholder: "Enter Special Allowance	",
    required: true,
  },
  {
    id: 12,
    label: "Gross Salary *",
    type: "number",
    placeholder: "Enter Special Allowance	",
    required: true,
  },

  {
    id: 13,
    label: "Employer's Provident Fund*",
    type: "number",
    placeholder: "Enter Employer's Provident Fund",
    required: true,
  },

  {
    id: 14,
    label: "Accident & Medical Insurance *",
    type: "number",
    placeholder: "Enter Special Allowance	",
    required: true,
  },
  {
    id: 15,
    label: "ESIC *",
    type: "number",
    placeholder: "Enter ESIC",
    required: true,
  },
]


export const empSubTitle =
  "Fill in the details of the employee to generate the offer letter.";