import create from "zustand";
import { devtools, persist } from "zustand/middleware";

// define the store
const useStore = create(
  devtools(
    persist( 
      (set, get) => ({
        userData: {
          name: "",
          empid: "",
          empnumber: "",
          location: "",
          designation: "",
          dateofjoining: "",
          dateofrelieving: "",
          authsignature: "",
          ctc: 0,
          basic: 0,
          hra: 0,
          ta: 0,
          tr: 0,
          ma: 0,
          sa: 0,
          gs: 0,
          epf: 0,
          mi: 0,
          esic: 0,
        },
        addData: (data) => {
          set((state) => ({
            userData: { data },
          }));
        },
      }),
      {
        name: 'user-data', // unique name
        getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
      }
    )
  )
);

export default useStore;
