import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormatDate } from "../Components/FormatDate";
import {
  employeeDetails,
  empSubTitle,
  empTitle,
} from "../Components/FormInput/ConfirmFormInputHelper";
import FormInput from "../Components/FormInput/FormInput";
import "../Pages/ExperienceLetterPage/ExperienceLetterPageStyling.css";
import useStore from "../Store/store";
import ConfirmationLetter from "../Templates/ConfirmationLetter";
import Button from "../UI/Button";

export const ConfirmationLetterPage = () => {
  const [empName, setEmpName] = useState();
  const [empNum, setNum] = useState();
  const [designation, setDesignation] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const addData = useStore((state) => state.addData);
  const data = useStore.getState().userData;

  useEffect(() => {
    if (data.data) {
      data.data.name && setEmpName(data.data.name);
      data.data.empnumber && setNum(data.data.empnumber);
      data.data.designation && setDesignation(data.data.designation);
      data.data.dateofjoining && setDateOfJoining(data.data.dateofjoining);
    }
  }, [data]);

  function handleOnChange(valueFrom, value) {
    if (valueFrom === 1) {
      setEmpName(value);
    }
    if (valueFrom === 2) {
      setNum(value);
    }
    if (valueFrom === 3) {
      setDateOfJoining(value);
    }
    if (valueFrom === 4) {
      setDesignation(value);
    }
  }

  const onClickHandler = () => {
    addData({
      name: empName,
      empnumber: empNum,
      designation: designation,
      dateofjoining: dateOfJoining,
    });
    navigate("/preview", { state: prevRoute });
  };

  return (
    <div className="template-container">
      <div className="container hero-container">
        <div className="form-container">
          <div className="title-container">
            <h1 className="title">{empTitle}</h1>
            <h4 className="sub-title">{empSubTitle}</h4>
          </div>
          {employeeDetails.map((item, index) => (
            <FormInput
              key={index}
              label={item.label}
              type={item.type}
              onChange={(e) => handleOnChange(item.id, e.target.value)}
              value={
                item.id === 1
                  ? empName
                  : item.id === 2
                  ? empNum
                  : item.id === 3
                  ? dateOfJoining
                  : item.id === 4
                  ? designation
                  : item.value
              }
              placeholder={item.placeholder}
              required={item.required}
            />
          ))}
        </div>
        <div className="buttonToHide">
          <Button className="preview-button" onClick={onClickHandler}>
            Preview
          </Button>
        </div>
      </div>
      <div className="preview-content">
        <Button className="preview-button" onClick={onClickHandler}>
          Preview
        </Button>
        <ConfirmationLetter
          empName={empName}
          empNum={empNum}
          dateOfJoining={FormatDate(dateOfJoining)}
          designation={designation}
        />
      </div>
    </div>
  );
};
