import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormatDate } from "../Components/FormatDate";
import useStore from "../Store/store";
import AppointmentLetter from "../Templates/AppointmentLetter";
import ConfirmationLetter from "../Templates/ConfirmationLetter";
import ExperienceLetter from "../Templates/ExperienceLetter/ExperienceLetter";
import OfferLetter from "../Templates/OfferLetter";
import Button from "../UI/Button";

export const PreviewPage = () => {
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const route = prevRoute.state.pathname;
  const offerLetter = "/offer-letter";
  const experienceLetter = "/experience-letter";
  const appointmentLetter = "/appointment-letter";
  const confirmationLetter = "/confirmation-letter";
  const data = useStore.getState().userData;

  const printDocument = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const content = document.getElementById("container-to-print");

  
    doc
      .html(content, {
        width: width,
        height: height,
        windowWidth: 800,
        margin: [20, 40, 50, 40],
        html2canvas: { scale: 0.65 },
        // autoPaging : "text"
      })

      .then(() => {
        // doc.save(
        //   route === offerLetter
        //     ? "Offer Letter.pdf"
        //     : route === appointmentLetter
        //     ? "Appointment Letter.pdf"
        //     : route === confirmationLetter
        //     ? "Confirmation Letter.pdf"
        //     : route === experienceLetter
        //     ? "Experience Letter.pdf"
        //     : "Relieving Letter.pdf"
        // );

       

        window.open(doc.output("bloburl", "_blank"));

      })
  };

  return (
    <>
      <div className="preview-container">
        <div className="preview-outer-container">
          <div className="container-to-print">
            <div className="preview-button-container">
              <Button
                id="divBackButton"
                className="preview-button"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button className="preview-button" onClick={printDocument}>
                Download
              </Button>
            </div>

            <div id="container-to-print">
              <div className="responsive-container">
              {route === offerLetter ? (
                <OfferLetter
                  name={data.data.name}
                  location={data.data.location}
                  designation={data.data.designation}
                  doj={FormatDate(data.data.dateofjoining)}
                  ctc={data.data.ctc}
                  basic={data.data.basic}
                  hra={data.data.hra}
                  ta={data.data.ta}
                  tr={data.data.tr}
                  ma={data.data.ma}
                  sa={data.data.sa}
                  gs={data.data.gs}
                  epf={data.data.epf}
                  mi={data.data.mi}
                  esic={data.data.esic}
                />
              ) : route === confirmationLetter ? (
                <ConfirmationLetter
                  empName={data.data.name}
                  empNum={data.data.empnumber}
                  dateOfJoining={FormatDate(data.data.dateofjoining)}
                  designation={data.data.designation}
                />
              ) : route === appointmentLetter ? (
                <AppointmentLetter
                  empName={data.data.name}
                  designation={data.data.designation}
                  dateOfJoining={FormatDate(data.data.dateofjoining)}
                />
              ) : (
                <ExperienceLetter
                  empName={data.data.name}
                  empId={data.data.empid}
                  designation={data.data.designation}
                  dateOfJoining={FormatDate(data.data.dateofjoining)}
                  dateOfRelieving={FormatDate(data.data.dateofrelieving)}
                  authSign={data.data.authsignature}
                />
              )}
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
