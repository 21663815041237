import { useEffect } from "react";
import { getApi } from "../Utils/http";

export async function getEmployeesList() {
    let url = "https://lucidatechnologies-team.freshteam.com/api/employees";

  
    const employeesList = await getApi(url)
      .then((response) => {
        if (response?.data?.length) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error)
        return [];
      });
    return employeesList;
  }

  