import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormatDate } from "../Components/FormatDate";
import FormInput from "../Components/FormInput/FormInput";
import {
  employeeDetails,
  empSubTitle,
  hrDetails,
} from "../Components/FormInput/OfferLetterHelper";
import {
  empTitle,
  hrSubTitle,
  hrTitle,
} from "../Helpers/ExperienceLetterHelper";
import useStore from "../Store/store";
import OfferLetter from "../Templates/OfferLetter";
import Button from "../UI/Button";

const OfferLetterPage = () => {
  const [detailsType, setDetailsType] = useState(empTitle);
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [designation, setDesignation] = useState();
  const [doj, setDOJ] = useState();
  const [ctc, setCTC] = useState();
  const [basic, setBasic] = useState();
  const [hra, setHRA] = useState();
  const [ta, setTA] = useState(); //Transport Allowance
  const [tr, setTR] = useState(); //Telephone Reimbursement
  const [ma, setMA] = useState(); //Medical Allowance
  const [sa, setSA] = useState(); //Special Allowance
  const [gs, setGS] = useState(); //Gross Salary
  const [epf, setEPF] = useState(); //Employer's Provident Fund
  const [mi, setMI] = useState(); //Accident & Medical Insurance
  const [esic, setESIC] = useState(); //ESIC

  const addData = useStore((state) => state.addData);
  const data = useStore.getState().userData;

  useEffect(() => {
    if (data.data) {
      data.data.name && setName(data.data.name);
      data.data.location && setLocation(data.data.location);
      data.data.designation && setDesignation(data.data.designation);
      data.data.dateofjoining && setDOJ(data.data.dateofjoining);
      data.data.ctc && setCTC(data.data.ctc);
      data.data.basic && setBasic(data.data.basic);
      data.data.hra && setHRA(data.data.hra);
      data.data.ta && setTA(data.data.ta);
      data.data.tr && setTR(data.data.tr);
      data.data.ma && setMA(data.data.ma);
      data.data.sa && setSA(data.data.sa);
      data.data.gs && setGS(data.data.gs);
      data.data.epf && setEPF(data.data.epf);
      data.data.mi && setMI(data.data.mi);
      data.data.esic && setESIC(data.data.esic);
    }
  }, [data]);

  function handleOnChange(valueFrom, value) {
    if (valueFrom === 1) {
      setName(value);
    }
    if (valueFrom === 2) {
      setLocation(value);
    }
    if (valueFrom === 3) {
      setDesignation(value);
    }
    if (valueFrom === 4) {
      setDOJ(value);
    }
    if (valueFrom === 5) {
      setCTC(value);
    }
    if (valueFrom === 6) {
      setBasic(value);
    }
    if (valueFrom === 7) {
      setHRA(value);
    }
    if (valueFrom === 8) {
      setTA(value);
    }
    if (valueFrom === 9) {
      setTR(value);
    }

    if (valueFrom === 10) {
      setMA(value);
    }
    if (valueFrom === 11) {
      setSA(value);
    }
    if (valueFrom === 12) {
      setGS(value);
    }
    if (valueFrom === 13) {
      setEPF(value);
    }
    if (valueFrom === 14) {
      setMI(value);
    }
    if (valueFrom === 15) {
      setESIC(value);
    }
  }

  const onClickHandler = () => {
    addData({
      name: name,
      location: location,
      designation: designation,
      dateofjoining: doj,
      ctc: ctc,
      basic: basic,
      hra: hra,
      ta: ta,
      tr: tr,
      ma: ma,
      sa: sa,
      gs: gs,
      epf: epf,
      mi: mi,
      esic: esic,
    });
    navigate("/preview", { state: prevRoute });
  };

  return (
    <div className="template-container">
      <div className="container hero-container">
        <div className="form-container">
          <div className="title-container">
            <h1 className="title">
              {detailsType === empTitle ? empTitle : hrTitle}
            </h1>
            <h4 className="sub-title">
              {detailsType === empTitle ? empSubTitle : hrSubTitle}
            </h4>
          </div>
          {(detailsType === empTitle ? employeeDetails : hrDetails).map(
            (item, index) => (
              <FormInput
                key={index}
                label={item.label}
                type={item.type}
                onChange={(e) => handleOnChange(item.id, e.target.value)}
                value={
                  item.id === 1
                    ? name
                    : item.id === 2
                    ? location
                    : item.id === 3
                    ? designation
                    : item.id === 4
                    ? doj
                    : item.id === 5
                    ? ctc
                    : item.id === 6
                    ? basic
                    : item.id === 7
                    ? hra
                    : item.id === 8
                    ? ta
                    : item.id === 9
                    ? tr
                    : item.id === 10
                    ? ma
                    : item.id === 11
                    ? sa
                    : item.id === 12
                    ? gs
                    : item.id === 13
                    ? epf
                    : item.id === 14
                    ? mi
                    : item.id === 15
                    ? esic
                    : item.value
                }
                placeholder={item.placeholder}
                required={item.required}
              />
            )
          )}
        </div>

        {detailsType != empTitle && (
          <div className="button-container">
            <Button
              onClick={() =>
                detailsType === hrTitle && setDetailsType(empTitle)
              }
            >
              Prev
            </Button>
            <div className="buttonToHide">
              <Button className="preview-button" onClick={onClickHandler}>
                Preview
              </Button>
            </div>
          </div>
        )}
        {detailsType != hrTitle && (
          <Button
            onClick={() => detailsType === empTitle && setDetailsType(hrTitle)}
          >
            Next
          </Button>
        )}
      </div>
      <div className="preview-content">
        <Button className="preview-button" onClick={onClickHandler}>
          Preview
        </Button>
        <OfferLetter
          name={name}
          location={location}
          designation={designation}
          doj={FormatDate(doj)}
          ctc={ctc}
          basic={basic}
          hra={hra}
          ta={ta}
          tr={tr}
          ma={ma}
          sa={sa}
          gs={gs}
          epf={epf}
          mi={mi}
          esic={esic}
        />
      </div>
    </div>
  );
};

export default OfferLetterPage;
