import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormInput from "../../Components/FormInput/FormInput";
import {
  employeeDetails,
  empTitle,
  experienceSubTitle,
  hrDetails,
  hrSubTitle,
  hrTitle,
  relievingSubTitle,
} from "../../Helpers/ExperienceLetterHelper";
import useStore from "../../Store/store";
import ExperienceLetter from "../../Templates/ExperienceLetter/ExperienceLetter";
import Button from "../../UI/Button";
import "./ExperienceLetterPageStyling.css";
import { FormatDate } from "../../Components/FormatDate";

export const ExperienceLetterPage = () => {
  const data = useStore.getState().userData;
  const [detailsType, setDetailsType] = useState(empTitle);
  const [empName, setEmpName] = useState();
  const [empId, setEmpId] = useState();
  const [designation, setDesignation] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();
  const [dateOfRelieving, setDateOfRelieving] = useState();
  const [authSignature, setAuthSignature] = useState();
  const [authFileName, setAuthFileName] = useState();
  const [showIcon, setShowIcon] = useState(false);
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const currentpage = prevRoute.pathname;
  const experienceLetter = "/experience-letter";
  const addData = useStore((state) => state.addData);

  useEffect(() => {
    if (data.data) {
      data.data.name && setEmpName(data.data.name);
      data.data.empid && setEmpId(data.data.empid);
      data.data.designation && setDesignation(data.data.designation);
      data.data.dateofjoining && setDateOfJoining(data.data.dateofjoining);
      data.data.dateofrelieving &&
        setDateOfRelieving(data.data.dateofrelieving);
      data.data.authsignature && setAuthSignature(data.data.authsignature);
    }
  }, [data]);

  function handleOnChange(valueFrom, value) {
    if (valueFrom === 1) {
      setEmpName(value);
    }
    if (valueFrom === 2) {
      setEmpId(value);
    }
    if (valueFrom === 3) {
      // value.split("-").reverse().join("-");

      // const [year, month, day] = value.split("-");
      // console.log(year, month, day);
      // let newDate = `${day}-${month}-${year}`;
      // console.log(newDate);

      setDateOfJoining(value);
    }
    if (valueFrom === 4) {
      setDateOfRelieving(value);
    }
    if (valueFrom === 5) {
      setDesignation(value);
    }
    if (valueFrom === 6) {
      if(value.length === 1){
        setAuthFileName(value[0].name)
        setAuthSignature(URL.createObjectURL(value[0]));
        setShowIcon(true)
      }else {
        setAuthFileName(authFileName && authFileName);
        setAuthSignature(authSignature && authSignature);
      }
    }
  }

  const onClickHandler = () => {
    addData({
      name: empName,
      empid: empId,
      designation: designation,
      dateofjoining: dateOfJoining,
      dateofrelieving: dateOfRelieving,
      authsignature: authSignature,
    });
    navigate("/preview", { state: prevRoute });
  };

  const handleClear = () => {
    setAuthFileName("No file chosen");
    setAuthSignature("./images/sign.png");
    setShowIcon(false);
  }

  return (
    <div className="template-container">
      <div className="container hero-container">
        <div className="form-container">
          <div className="title-container">
            <h1 className="title">
              {detailsType === empTitle ? empTitle : hrTitle}
            </h1>
            <h4 className="sub-title">
              {detailsType === empTitle
                ? currentpage === experienceLetter
                  ? experienceSubTitle
                  : relievingSubTitle
                : hrSubTitle}
            </h4>
          </div>
          {(detailsType === empTitle ? employeeDetails : hrDetails).map(
            (item, index) => (
              <FormInput
                key={index}
                label={item.label}
                type={item.type}
                onChange={(e) =>
                  handleOnChange(
                    item.id,
                    item.id === 6 ? e.target.files : e.target.value
                  )
                }
                value={
                  item.id === 1 ? empName :
                    item.id === 2 ? empId :
                      item.id === 3 ? dateOfJoining :
                        item.id === 4 ? dateOfRelieving :
                          item.id === 5 ? designation :
                            item.value }
                placeholder={item.placeholder}
                required={item.required}
                accept={item.accept}
                onClearClick={handleClear}
                showIcon={showIcon}
                fileName={authFileName}
              />
            )
          )}
        </div>

        {detailsType != empTitle && (
          <div className="button-container">
            <Button
              onClick={() =>
                detailsType === hrTitle && setDetailsType(empTitle)
              }
            >
              Prev
            </Button>
            <div className="buttonToHide">
              <Button className="preview-button" onClick={onClickHandler}>
                Preview
              </Button>
            </div>
          </div>
        )}
        {detailsType != hrTitle && (
          <Button
            onClick={() => detailsType === empTitle && setDetailsType(hrTitle)}
          >
            Next
          </Button>
        )}
      </div>
      <div className="preview-content">
        <Button className="preview-button" onClick={onClickHandler}>
          Preview
        </Button>
        <ExperienceLetter
          empName={empName}
          empId={empId}
          designation={designation}
          dateOfJoining={FormatDate(dateOfJoining)}
          dateOfRelieving={FormatDate(dateOfRelieving)}
          authSign={authSignature}
        />
      </div>
    </div>
  );
};
