import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import "./NavBar.css";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const returnToHomeHandler = () => {
    navigate("/");
  };

  return (
    <div className="nav-div">
      <Navbar collapseOnSelect fixed="top" expand="sm" className="navigation">
        <Container id="nav-header">
          <Navbar.Brand>
            <div className="logo" onClick={returnToHomeHandler}>
              <img src={logo} alt="logo   " />
            </div>
          </Navbar.Brand>

          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            <Nav activeKey={location.pathname} className="ms-auto navigation">
              <NavDropdown title="Generate Letters" id="basic-nav-dropdown">
                <NavDropdown.Item href="/experience-letter" className="mb-2">
                  Experience Letter
                </NavDropdown.Item>

                <NavDropdown.Item href="/offer-letter" className="mb-2">
                  Offer Letter
                </NavDropdown.Item>

                <NavDropdown.Item href="/appointment-letter" className="mb-2">
                  Appointment Letter
                </NavDropdown.Item>

                <NavDropdown.Item href="/confirmation-letter" className="mb-2">
                  Confirmation Letter
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
